export default{
  //本地调试
  // roomAdminMs: 'http://192.168.3.54:22000',
  // uploadUrl: 'https://homestay.yijian.hk'

  //测试
  // roomAdminMs: 'https://agent.admin.yijian.hk',
  roomAdminMs: 'https://agentpt.laiyijian.cn',
  uploadUrl: 'https://homestay.yijian.hk'

  //线上
  // roomAdminMs: 'https://agentap.laiyijian.com',
  // uploadUrl: 'https://homestay.yijiansz.com'
}
